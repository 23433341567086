<template>
	<span>
		<v-tooltip right>
			<template v-slot:activator="{ on }">
				<v-btn
					color="white"
					@click="dialog = true"
					icon
					v-on="on"
					><v-icon>mdi-comment</v-icon></v-btn
				>
			</template>
			{{ $t("misc.comment_on_goal") }}
		</v-tooltip>
		<v-dialog v-model="dialog" content-class="mw-small-dialog">
			<v-card>
				<v-card-title> {{ $t("misc.comment_on_goal") }} </v-card-title>
				<v-card-text>
					<v-textarea v-model="comments"></v-textarea>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="save">
						{{ $t("buttons.save") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
</template>

<script>

// Displays a button which allows the user to add a comment to their goal response
// @group Survey
export default {
	name: "Comment",
	data: () => {
		return {
			dialog: false,
			comments: "",
		};
	},
	computed: {
		response() {
			return this.$store.getters.currentResponseData;
		},
	},
	watch: {
		response: {
            immediate: true, 
            deep: true, 
            handler(){
                this.comments = this.response.comments || "";
            }
		},
	},
	methods: {
		// @vuese
		// On click, save comment to the responseData document
		save() {
            const self = this;
			if( self.response ){
				self.$store.dispatch("responseData/patch", {
					id: self.response.id,
					comments: self.comments,
				}).then( () => {
					self.dialog = false;
				})
			}
			else{
				self.$store.dispatch("responseData/newResponse", {
					comments: self.comments,
				}).then( () => {
					self.dialog = false;
				})

			}
		},
	},
};
</script>
