<template>
	<div>
		<p class="subtitle-1">
			<b>{{ question.title }}</b>
		</p>
		<p v-if="answerResponse">
			{{ answerResponse }}
		</p>
		<p v-else class="no-answer">
			{{ $t("survey.no_answer") }}
		</p>
	</div>
</template>

<style lang="less">
.no-answer {
	font-style: italic;
}
</style>

<script type="text/javascript">
// displays a single user's response to a single question
// @group Results
export default {
	name: "SingleQuestionResponse",
	props: {
		// the user's data
		data: { type: Object },
		// the ID of the question
		question: { type: String },
	},
	data: () => {
		return {};
	},
	// components: {

	// },
	computed: {
		answerResponse() {
			var value = this.data;
			if (!value) {
				return false;
			}
			if (!this.question.answerOptions) {
				return;
			}
			var response = Object.values(this.question.answerOptions).find(
				(d) => d.value == value
			);
			return response.text;
		},
	},
	// methods: {
	// }
	// created(){

	// }
};
//
</script>
"
