<template>
	<div id="section-tools" class="rounded-xl pa-1 pl-2 pr-2">
		<create-poll class="mr-1"></create-poll>
		<comments></comments>
	</div>
</template>

<style lang="less"></style>
<script type="text/javascript">
import Comments from "@c/survey/tools/Comments";
import CreatePoll from "@c/survey/poll/Create";
// Displays buttons above a section - comments and polls
// @group Survey 
export default {
	name: "SectionTools",
	props: {
		data: {},
	},
	components: {
		Comments,
		CreatePoll,
	},
	computed: {
		lead() {
			return this.$store.getters["assessment/isLead"];
		},
	},
};
//
</script>
"
