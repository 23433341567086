<template>
	<span>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" color="white" icon @click="dialog = true">
					<v-icon>mdi-account-plus</v-icon>
				</v-btn>
			</template>
			{{ $t("polls.launch") }}
		</v-tooltip>
		<v-dialog v-model="dialog" content-class="mw-medium-dialog">
			<v-card>
				<v-card-title>{{ $t("polls.overview") }}</v-card-title>
				<v-card-text>
					<p>{{ $t("polls.intro") }}</p>
					<existing-polls></existing-polls>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="newPoll" color="primary">{{
						$t("polls.new")
					}}</v-btn>
					<v-btn @click="dialog = false">{{ $t("buttons.close") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
</template>

<script type="text/javascript">
import ExistingPolls from "@c/survey/poll/ExistingPolls";

// Displays a dialog for polls for a single question including a button to create a new poll and a list of existing polls
// @group Polls
export default {
	name: "CreatePoll",
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
		ExistingPolls,
	},
	methods: {
		// @vuese
		// Dispatches an event to create a new collaboration document for the currently visible goal / part / department
		newPoll() {
			this.$store.dispatch("collaborations/newCurrentPoll");
		},
	},
};
//
</script>
"
