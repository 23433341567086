<template>
	<span>
		<v-btn
			rounded
			:small="isMobile"
			@click="goPrev"
			color="primary"
			v-if="!isFirst"
			class="mr-2"
			>{{ $t("buttons.back") }}</v-btn
		>
		<v-btn
			rounded
			:small="isMobile"
			@click="goNext"
			color="primary"
			v-if="!isLast"
			>{{ $t("buttons.next") }}</v-btn
		>
		<return v-if="isDelegate && isLastInDepartment" :disabled="!canReturn" :id="department"></return>
	</span>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Return from "@c/survey/tools/Return";

// Displays a buttons which allow user to move through the assessment e.g. "next", "previous" 
// or at the end of the survey "complete"/"return"
// @group Survey
export default {
	name: "NextSection",
	components: {
		Return,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		isDelegate() {
			return this.$store.getters["assessment/isDelegate"];
		},
		department() {
			return this.$store.state.current.department;
		},
		response() {
			return this.$store.getters.responseMap[this.department];
		},
		completionRate(){
			var rates = this.$store.getters.rates;
			var department = this.department;
			var values = rates.filter( r => r.department == department );
			return this.mwsurveyutilities.averageCompletionRate(values)
		}, 	
		canReturn(){
			return this.completionRate == 1;
		},
		next() {
			return this.$store.getters.next;
		},
		previous() {
			return this.$store.getters.previous;
		},
		isFirst() {
			return !this.previous;
		},
		isLast() {
			return !this.next;
		},
		isLastInDepartment() {
			return this.isLast || this.next.department !== this.department;
		},
	},
	methods: {
		// @vuese
		// Goes to the previous view in the order
		goPrev() {
			// saves data - emitted on click of navigational buttons before moving to the next view
			this.$emit("save");
			if (this.previous) {
				this.$store.commit("GO_TO", this.previous);
			}
		},
		// @vuese
		// Goes to the next view in the order
		goNext() {
			// saves data - emitted on click of navigational buttons before moving to the next view
			this.$emit("save");

			if (this.next) {
				this.$store.commit("GO_TO", this.next);
			}
		},
	},
};
//
</script>
"
