<template>
	<v-sheet color="transparent">
		<survey-section v-if="currentView"></survey-section>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Vuex from "vuex";
import SurveySection from "@c/survey/render/Section";
export default {
	name: "Assessment",
	props: {},
	data: () => {
		return {};
	},
	components: {
		SurveySection,
	},
	computed: {
		...Vuex.mapState({
			assessment: (state) => state.assessment.data,
		}),
		currentView() {
			return this.$store.getters.currentView;
		},
		canAccessAssessment() {
			return this.$store.getters["assessment/canAccessAssessment"];
		},
		filter(){
			return this.$store.state.tag_filter;
		}
	},
	watch: {
		currentView: {
			immediate: true,
			handler(value) {
				if (!value) {
					var first = this.$store.getters.first;
					if (!first) {
						this.$router.replace("/home");
					} else {
						this.$store.commit("GO_TO", first);
					}
				}
			},
		},
	},
};
//
</script>
"
