<template>
	<div>
		<p v-if="!havePolls">{{ $t("polls.none_found") }}</p>
		<v-list dense>
			<edit-poll
				v-for="poll in currentPolls"
				:key="poll.id"
				:id="poll.id"
			></edit-poll>
		</v-list>
	</div>
</template>

<style lang="less"></style>

<script type="text/javascript">
import EditPoll from "@c/survey/poll/EditPoll";
// Lists all current polls for the current department / part / goal or displays a message where no polls found
// @group Survey
export default {
	name: "ExistingPoll",
	props: {},
	components: {
		EditPoll,
	},
	computed: {
		currentPolls() {
			return this.$store.getters["collaborations/currentViewPolls"];
		},
		havePolls() {
			return this.currentPolls.length > 0;
		},
	},
};
</script>
