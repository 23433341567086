<template>
	<div>
		<v-list-item v-if="poll">  
			<v-list-item-icon small>
				<poll-response-avatar
					:id="id"
					v-if="poll.status == 'submitted'"
				></poll-response-avatar>
			</v-list-item-icon>
			<v-list-item-content three-line>
				<v-list-item-title>
					<v-text-field
						class="mt-0"
						outlined
						:placeholder="$t('polls.nickname')"
						v-model="nickname"
						dense
						hide-details
					></v-text-field>
				</v-list-item-title>
				<v-list-item-subtitle>
					{{ link }}
				</v-list-item-subtitle>
				<v-list-item-subtitle class="text-right">
					<copy-button :text="link" small></copy-button>
					<v-tooltip right>
						<template v-slot:activator="{ on }">
							<v-btn icon @click="deletePoll" small v-on="on">
								<v-icon small>mdi-trash-can</v-icon>
							</v-btn>
						</template>
						{{ $t("buttons.delete") }}
					</v-tooltip>
					<ViewPoll v-if="poll.response" :poll="poll" :id="id"></ViewPoll>
				</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-action> </v-list-item-action>
		</v-list-item>
	</div>
</template>

<style lang="less" scoped>
.v-list-item__icon {
	margin-right: 10px !important;
}
</style>

<script type="text/javascript">
import ViewPoll from "@c/survey/poll/ViewPoll";
import PollResponseAvatar from "@c/survey/poll/PollResponseAvatar";
import CopyButton from "@c/buttons/CopyButton";
// This displays existing polls for a goal and allows the user to edit the nickname, delete the poll and copy the link
// @group Polls
export default {
	name: "EditPoll",
	props: {
		// the ID of the poll document 
		id: { type: String, required: true },
	},
	data: () => {
		return {
			nickname: "",
		};
	},
	components: {
		CopyButton,
		ViewPoll,
		PollResponseAvatar,
	},
	computed: {
		poll() {
			return this.$store.state.collaborations.data[this.id];
		},
		link() {
			var url = window.location.origin;
			return url + "/poll/" + this.id;
		},
	},
	methods: {
		// @vuese
		// deletes document - n.b. doesn't use vuex-easy-firestore delete as this is permanently removed from db
		// this "deleteDoc" just sets the status to "deleted"
		deletePoll() {
			this.$store.dispatch("deleteDoc", {
				id: this.id,
				storeModule: "collaborations",
			});
		},
	},
	watch: {
		nickname(value) {
			if (!value) {
				return;
			}
			if (value == this.poll.nickname) {
				return;
			}
			this.$store.dispatch("collaborations/patch", { id: this.id, nickname: value });
		},
	},
	created() {
		this.nickname = this.poll.nickname;
	},
};
//
</script>
"
