<template>
	<v-sheet color="transparent" class="ig-survey-wrapper">
		<template v-if="accessGranted && goal">
			<p class="text-body-1 text-md-h5 mb-6">{{ goal.name }}</p>
			<goal-description v-bind="goal"></goal-description>
			<questions
				:questions="questions"
				:error="error"
				:errorMessage="errorMessage ? errorMessage : ''"
				:data="data"
				@update="updateValue"
			></questions>
			<p class="text-right">
				<v-btn
					rounded
					:small="isMobile"
					@click="save"
					color="primary"
					class="mr-2"
					>{{ $t("buttons.save") }}</v-btn
				>
				<change-view @save="save"></change-view>
			</p>
			<section-tools :data="data"></section-tools>
			<saved v-model="saved"></saved>
		</template>
		<v-alert v-else color="secondary">
			{{ $t("survey.access_denied") }}
		</v-alert>
	</v-sheet>
</template>

<style lang="less">
@import "../../../../src/assets/css/survey/survey.less";
</style>

<script type="text/javascript">
import Vue from "vue";
import Vuex from "vuex";
import Saved from "@c/notices/Saved";
import ChangeView from "@c/survey/tools/ChangeView";
import Questions from "@c/survey/render/Questions";
import GoalDescription from "@c/survey/render/GoalDescription";
import SectionTools from "@c/survey/tools/SectionTools";
// The complete survey view: displays the questions for a single goal, the section tools and the buttons to save or navigate between goals
// @group Survey
export default {
	name: "Section",
	data: () => {
		return {
			saved: false,
			saving: false,
			data: {},
		};
	},
	components: {
		Questions,
		Saved,
		ChangeView,
		GoalDescription,
		SectionTools,
	},
	computed: {
		...Vuex.mapState({
			assessment: (state) => state.assessment.data,
		}),
		response() {
			return this.$store.getters.currentResponse;
		},
		view() {
			return this.$store.state.current;
		},
		department() {
			return this.$store.state.departments.data[this.view.department];
		},
		part() {
			return this.$store.state.sectionParts.data[this.view.part];
		},
		goal() {
			return this.$store.state.goals.data[this.view.goal];
		},
		goalGroup() {
			return this.$store.state.goalGroups.data[this.part.goalGroup];
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		responseData() {
			return this.$store.getters.currentResponseData;
		},
		answers() {
			return this.responseData ? this.responseData.data : {};
		},
		questions() {
			return this.$store.getters["questions/defaultOrder"];
		},
		currentStatus() {
			return this.$store.getters.currentStatus;
		},
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		isDelegate() {
			return this.$store.getters["assessment/isDelegate"];
		},
		error() {
			if (this.saving) {
				return true;
			}
			if (this.isLead) {
				return ["delegated", "complete"].includes(this.currentStatus);
			} else if (this.isDelegate) {
				return ["returned", "complete"].includes(this.currentStatus);
			}
			return false;
		},
		errorMessage() {
			if (this.saving) {
				return this.$t("misc.saving");
			}
			var errors = {
				delegated: this.$t("misc.delegation_warning"),
				complete: this.$t("misc.completion_warning"),
			};
			return errors[this.currentStatus] || "";
		},
		accessGranted() {
			return (
				this.$store.getters.currentView &&
				this.$store.getters["assessment/canAccessAssessment"]
			);
		},
	},
	methods: {
		// @vuese
		// Sets the value when the answer is selected
		// @arg id - the question id 
		// @arg value - the value to set the question response to
		updateValue(id, value) {
			if (this.data[id] !== value) {
				Vue.set(this.data, id, value);
			}
		},
		// Saves or updates the responseData document and sets saved to true 
		save() {
			const self = this;
			let id = self.responseData.id || false;
			self.saving = true;
			if (!id) {
				self.$store
					.dispatch("responseData/newResponse", { data: self.data })
					.then(() => {
						self.saved = true;
						self.saving = false;
					})
					.catch(() => {
						self.error = true;
					});
			} else {
				self.$store
					.dispatch("responseData/set", { id, data: self.data })
					.then(() => {
						self.saved = true;
						self.saving = false;
					})
					.catch(() => {
						self.error = true;
					});
			}
		},
		// @vuese
		// resets the data - fired when switching between goal views
		resetData() {
			this.data = {};
		},
	},
	watch: {
		answers: {
			immediate: true,
			handler(value) {
				this.data = value;
			},
		},
	},
};
//
</script>
"
