<template>
	<div>
		<p class="overline">
			{{ updated.toDate() | moment("hh:mm, MMMM Do YYYY") }}
		</p>
		<single-question-response
			v-for="question in questions"
			:key="question.id"
			:data="data[question.id]"
			:question="question"
		></single-question-response>
	</div>
</template>

<style lang="less"></style>

<script type="text/javascript">
import SingleQuestionResponse from "@c/survey/results/SingleQuestionResponse";
// Displays one users response to all questions
// @group Results
export default {
	name: "SingleResponse",
	props: {
		// the user's data
		data: { type: Object },
		// the date at which the response was updated 
		updated: { type: [Date, String, Object ] },
	},
	components: {
		SingleQuestionResponse,
	},
	computed: {
		questions() {
			return this.$store.getters["questions/defaultOrder"];
		},
	},
};
//
</script>
"
