<template>
	<v-expansion-panels flat color="accent" class="mb-2" v-if="description">
		<v-expansion-panel class="help-text">
			<v-expansion-panel-header>
				<span color="primary">
					<v-icon class="mr-2" color="primary">mdi-information</v-icon>Mehr dazu
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				{{ description }}
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>
<style lang="less" scoped>
@import "../../../../src/assets/css/variables.less";
    .help-text {
        font-size: 0.8em;
        background: @ig-accent !important;
        color: @primary !important;
    }
</style>

<script>
export default {
	name: "GoalDescription",
	props: {
		name: { type: String },
		id: { type: String },
		description: { type: String },
	},
};
</script>
