<template>
	<v-dialog v-model="dialog" content-class="mw-medium-dialog">
		<template v-slot:activator="{ on }">
			<v-btn small icon v-on="on" v-if="poll.response">
				<v-icon small>mdi-magnify</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				{{ poll.nickname || $t("polls.button") }}
			</v-card-title>
			<v-card-text>
				<single-response
					:updated="poll.updated_at"
					:data="poll.response"
				></single-response>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="dialog = false">{{ $t("buttons.close") }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script type="text/javascript">
import SingleResponse from "@c/survey/results/SingleResponse";
// Displays a single poll response in a pop up
// @group Polls
export default {
	name: "ViewPoll",
	props: {
		// the ID of the poll document
		id: { type: String, required: true },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
		SingleResponse,
	},
	computed: {
		poll() {
			return this.$store.state.collaborations.data[this.id];
		},
	},
};
</script>
